<template>
    <div>
        <div class="card-toolbar mb-5">
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('journals_entries_account.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('journals_entries_account.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
<!--                <div>-->
<!--                    <div class="row justify-content-end p-4">-->
<!--                        <export-data :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.journals_entries_account')"></export-data>-->
<!--                    </div>-->
<!--                </div>-->

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                    <template slot="operation" slot-scope="props">
                        <div class="w-100">{{props.row.date}} (#{{props.row.id}})</div>
                        <div class="w-100" v-if="props.row.transaction_id">{{props.row.name}} #{{props.row.transaction_id}}</div>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-journals-entries-account",
        data() {
            return {
                mainRoute: 'accounting/chart-account/journal-entries/account',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    from_date: null,
                    to_date: null,
                },
                account_id: this.$route.params.account_id ? this.$route.params.account_id : null,
                columns: ['operation', 'debit', 'credit', 'after_amount'],
            }
        },

        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('journals_entries_account.operation')] = 'operation';
                fields[this.$t('journals_entries_account.debit')] = 'debit';
                fields[this.$t('journals_entries_account.credit')] = 'credit';
                fields[this.$t('journals_entries_account.balance_after')] = 'after_amount';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        operation: that.$t('journals_entries_account.operation'),
                        debit: that.$t('journals_entries_account.debit'),
                        credit: that.$t('journals_entries_account.credit'),
                        after_amount: that.$t('journals_entries_account.balance_after'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute+'/'+that.account_id, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.journals_entries_account")}]);
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.from_date = null;
                this.filters.to_date = null;

                this.$refs.table.refresh();
            },


        },
    };
</script>
